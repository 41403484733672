import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}
/*
*class: 'hide-submenu'
*este parametro nos ayuda a agregar una opcion al menu de forma oculta 
*y asi poder utilizar la ruta en el panel de contenido y al dar clic el menu quede como activo
*/
const MENUITEMS = [

{
  state: 'catalogos',
  name: 'Catálogos',
  type: 'sub',
  icon: 'apps',
  children: [
    {state: 'lotes', name: 'Lotes'},
    {state: 'destinos', name: 'Destinos'},
  ]
}, 

];

@Injectable()

export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }

}
