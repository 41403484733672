import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';


import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule} from './demo-material-module';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { AuthComponent } from './auth/auth.component';
import { LotesComponent } from './lotes/lotes.component';
import { AddloteComponent } from './lotes/addlote/addlote.component';
import { DestinosComponent } from './destinos/destinos.component';
import { AgregardestinoComponent } from './destinos/agregardestino/agregardestino.component';
import { EditardestinoComponent } from './destinos/editardestino/editardestino.component';
import { EliminardestinoComponent } from './destinos/eliminardestino/eliminardestino.component';
import { LoteComponent } from './lote/lote.component';
import { EliminarComponent } from './lotes/eliminar/eliminar.component';
import { EliminarloteComponent } from './lotes/eliminarlote/eliminarlote.component';
import { EditarComponent } from './sims/editar/editar.component';
import { EditloteComponent } from './lotes/editlote/editlote.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
};

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent, 
    AppBlankComponent,
    AppSidebarComponent,
    AuthComponent,
    LotesComponent,
    AddloteComponent,
    DestinosComponent,

    AgregardestinoComponent,

    EditardestinoComponent,

    EliminardestinoComponent,

    LoteComponent,

    EliminarComponent,

    EliminarloteComponent,

    EditarComponent,

    EditloteComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    PerfectScrollbarModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(AppRoutes),
    NgbModule
  ],
  entryComponents: [
       EliminardestinoComponent,
       EliminarloteComponent,
    ],
  providers: [
      {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }, {
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
