import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { RestService }from '../../rest.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-agregardestino',
  templateUrl: './agregardestino.component.html',
  styleUrls: ['./agregardestino.component.css']
})
export class AgregardestinoComponent implements OnInit {
  public form: FormGroup;

  mensajeError: string = '';
  mensaje: string = '';

  constructor
  (
    private fb: FormBuilder,
    private router: Router,
    public rest:RestService
  ) { }

  ngOnInit()
  {
    this.form = this.fb.group 
    ( 
      {
        destino: [null , Validators.compose ( [ Validators.required ] )],
        tipo: [null , Validators.compose ( [ Validators.required ] )],
        estado: [null , Validators.compose ( [ Validators.required ] )],
      } 
    );
  }
  onSubmit(formDirective)
  {
    let destino = this.form.value.destino;
    let tipo = this.form.value.tipo;
    let estado = this.form.value.estado;

    if(tipo == "CALL_OUT" || tipo=="SMS_OUT")
    {
      
      if(!isNaN(destino) && destino.length == 10)
      {
        this.mensajeError = '';
      }
      else
      {
        this.mensajeError = "Error: el telefono no es valido";
      }

    }
    else
    {
      var regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
   
     if(!regexp.test(destino))
     {
      this.mensajeError = "Error: la url no es valida";
     }
     else
     {
       this.mensajeError = '';
     }
      
    }

    if(this.mensajeError == '')
    {
      this.rest.addDestino(destino,tipo,estado).subscribe(Destino =>
      {
        if(Destino["success"] == false)
        {
          if(Destino["RETURN"] == "E")
          {
            this.mensajeError = "Error: el destino ya existe";
          }
          else
          {
            this.mensajeError = "Error: no se pudo agregar el destino";
          }
        }
        else
        {
          this.form.reset();
          formDirective.resetForm();
          this.mensaje="EL destino se ha agregado";
          this.router.navigate ( [ '/destinos' ] );
        }
      });
    }
  }
}
