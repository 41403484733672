import { Component, ViewChild, OnInit } from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {MatSnackBar} from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { RestService }from '../rest.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-lote',
  templateUrl: './lote.component.html',
  styleUrls: ['./lote.component.css']
})
export class LoteComponent implements OnInit {
  displayedColumns = ['SIM_ICCID', 'SIM_TELEFONO', 'SIM_IMEI','SIM_ID'];
  dataSource : MatTableDataSource<SimsData>;
  @ViewChild(MatPaginator) paginator : MatPaginator;
  @ViewChild(MatSort) sort : MatSort;

  
  ColumsDisplay = ['eventos','dias','sms_out','destino_sms','call_out','destino_call','sms_in','call_in','data'];
  dataEvent: MatTableDataSource<EventoData>;
  @ViewChild(MatPaginator) pagin : MatPaginator;
  @ViewChild(MatSort) sor : MatSort;

  eventos : EventoData[] = [];

  cliente : any = " ";
  lote : any = " ";
  operador : any = " ";
 lote_id;



  constructor(
    public snackBar: MatSnackBar,
    private rutaActiva: ActivatedRoute,
    public rest:RestService
    ) { }

  ngOnInit()
  {

    this.getLote( this.rutaActiva.snapshot.params.id);
  }

  getLote(id)
  {
    this.rest.getLote(id).subscribe(Lote=>{
    this.lote_id=Lote["DATA"]["LOT_ID"];
    this.cliente = Lote["DATA"]["LOT_CLIENTE"];
    this.lote = Lote["DATA"]["LOT_NUMERO"];
    this.operador = Lote["DATA"]["LOT_OPERADOR"];

    var configuracion=JSON.parse(Lote["DATA"]["LOT_CONFIGURACION"]);

    var i=1
    if(configuracion)
    {
    configuracion.forEach(
      (Element)=>
      {
        this.eventos.push
        (
          {
            evento:'Evento '+i,
            evento_dias_espera:Element["ESPERA"],

            sms_out_cantidad:Element["SMS_OUT"]["CANTIDAD"],
            sms_out_reintentos:Element["SMS_OUT"]["REINTENTOS"],
            sms_out_espera:Element["SMS_OUT"]["ESPERA_REINTENTAR"],
            sms_out_destinos:Element["SMS_OUT"]["DESTINO"],
            sms_out_mensaje:Element["SMS_OUT"]["MENSAJE"],

            sms_in_cantidad:Element["SMS_IN"]["CANTIDAD"],
            sms_in_detalles:Element["SMS_IN"]["REGEXP"],
            sms_in_reintentos:Element["SMS_IN"]["REINTENTOS"],
            sms_in_espera:Element["SMS_IN"]["ESPERA_REINTENTAR"],

            call_out_cantidad:Element["CALL_OUT"]["CANTIDAD"],
            call_out_duracion:Element["CALL_OUT"]["DURACION"],
            call_out_reintentos:Element["CALL_OUT"]["REINTENTOS"],
            call_out_espera:Element["CALL_OUT"]["ESPERA_REINTENTAR"],
            call_out_destino:Element["CALL_OUT"]["DESTINO"],

            call_in_cantidad:Element["CALL_IN"]["CANTIDAD"],
            call_in_duracion:Element["CALL_IN"]["DURACION"],
            call_in_reintentos:Element["CALL_IN"]["REINTENTOS"],
            call_in_espera:Element["CALL_IN"]["ESPERA_REINTENTAR"],

            data_cantidad:Element["DATA"]["CANTIDAD"],
            data_reintentos:Element["DATA"]["REINTENTOS"],
            data_espera:Element["DATA"]["ESPERA_REINTENTAR"],
          }
        );

        i++;
      }
    );
    this.dataEvent = new MatTableDataSource(this.eventos);
    this.dataEvent.paginator = this.pagin;
    this.dataEvent.sort = this.sor;
    }
 
    if(Lote["DATA"]["SIMS"])
    {

      const sims:SimsData[]=Lote["DATA"]["SIMS"];

      this.dataSource = new MatTableDataSource(sims);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    

    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
export interface SimsData
{
  SIM_ID:string,
  SIM_ICCID:string,
  SIM_TELEFONO:string,
  SIM_IMEI:string,
}
export interface EventoData
{
  evento:any,
  evento_dias_espera:any,

  sms_out_cantidad:any,
  sms_out_reintentos:any,
  sms_out_espera:any,
  sms_out_destinos:any,
  sms_out_mensaje:any,

  sms_in_cantidad:any,
  sms_in_detalles:any,
  sms_in_reintentos:any,
  sms_in_espera:any,

  call_out_cantidad:any,
  call_out_duracion:any,
  call_out_reintentos:any,
  call_out_espera:any,
  call_out_destino:any,

  call_in_cantidad:any,
  call_in_duracion:any,
  call_in_reintentos:any,
  call_in_espera:any,

  data_cantidad:any,
  data_reintentos:any,
  data_espera:any,
}
