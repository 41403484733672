import { Component, ViewChild , OnInit } from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {MatSnackBar} from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { RestService }from '../rest.service';

import {EliminarloteComponent} from "./eliminarlote/eliminarlote.component";
import {MatDialog} from "@angular/material";

@Component({
  selector: 'app-lotes',
  templateUrl: './lotes.component.html',
  styleUrls: ['./lotes.component.css']
})
export class LotesComponent implements OnInit {
  displayedColumns = ['LOT_CLIENTE', 'LOT_OPERADOR', 'LOT_NUMERO','link','opciones'];
  dataSource: MatTableDataSource<LotesData>;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor
  (
    public snackBar: MatSnackBar,
    public rest:RestService,
    public dialogo: MatDialog
  )
  { }

  ngOnInit() 
  {
    this.getLotes();
  }
  getLotes()
  {
    this.rest.getLotes().subscribe
    (
      Lotes =>
      {
        const lotes:LotesData[]=Lotes["DESTINOS"];      
        this.dataSource = new MatTableDataSource(lotes);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    );
  }

  eliminar(id)
  {
    const dialogEliminiar=this.dialogo.open
    (
      EliminarloteComponent,
      {
        width : '500px',
      }
    );

    dialogEliminiar.afterClosed().subscribe
    (
      result =>
      {
        if(result == true)
        {
          this.rest.deleteLote(id).subscribe
          (
            Respuesta=>
            {
              console.log(Respuesta);
              if(Respuesta["success"] == true)
              {
                this.getLotes();
              }
            }
          );
        }
      }
    );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
export interface LotesData
{
  LOT_ID:string,
  LOT_CLIENTE:string,
  LOT_NUMERO:string,
  LOT_OPERADOR:string,
}