import {Component, Inject, Injectable, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-eliminarlote',
  templateUrl: './eliminarlote.component.html',
  styleUrls: ['./eliminarlote.component.css']
})
export class EliminarloteComponent implements OnInit {

  constructor
  (
    private dialogRef: MatDialogRef<EliminarloteComponent>,
    @Inject(MAT_DIALOG_DATA) public data : any,
    private fb: FormBuilder, 
    private router: Router
  ) 
  {
     dialogRef.disableClose = false;
  }
  ngOnInit() {
  }
  public closeDialog(respuesta)
  {
    this.dialogRef.close(respuesta);
  }

}
