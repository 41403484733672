import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate()
  {
    if(localStorage.getItem('SESION')!=null)
    {
      var sesion = localStorage.getItem('SESION');
     
      sesion= atob(sesion);

      var dataSesion=sesion.split('_');

      var date = new Date();
      var fecha = date.getFullYear()+"-"+date.getMonth()+"-"+date.getDay();

      if(dataSesion[0]=="user:admin" && dataSesion[1] == "pass:admin" && dataSesion[2] == "fecha:"+fecha)
      {        
        return true;
      }
      else
      {
        localStorage.removeItem("SESION");
        this.router.navigate ( [ '/' ] );
        return false;
      }
    }
    else
    {
      this.router.navigate ( [ '/' ] );
      return false;
    }
  }
}
