import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs/internal/Observable";
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RestService
{
  //url:string='http://192.168.10.37:88/index.php?ACTION=';
  url:string='https://thyone.klayware.com/index.php?ACTION=';

  constructor(private http:HttpClient) 
  {}

  getDestinos()
  {
    var url_api=this.url+'SIMTOOLS.BE:C1.ACTION.DESTINOS.CONSULTAR';
    return this.http.get(url_api);
  }

  addDestino(destino,tarea,estado)
  {
    var url_api=this.url+'SIMTOOLS.BE:C1.ACTION.DESTINOS.AGREGAR';
     return this.http.post(url_api,{
      "DESTINO":destino,
      "TAREA":tarea,
      "ESTADO": estado,
    });
  }
  
  getDestino(id)
  {
    var url_api=this.url+'SIMTOOLS.BE:C1.ACTION.DESTINOS.OBTENER';
     return this.http.post(url_api,{
      "ID":id
    });
  }

  saveDestino(id,destino,tarea,estado)
  {
    var url_api=this.url+'SIMTOOLS.BE:C1.ACTION.DESTINOS.GUARDAR';
     return this.http.post(url_api,{
      "ID": id,
      "DESTINO": destino,
      "TAREA": tarea,
      "ESTADO": estado,

    });
  }

  deleteDestino(id)
  {
    var url_api=this.url+'SIMTOOLS.BE:C1.ACTION.DESTINOS.ELIMINAR';
     return this.http.post(url_api,{
      "ID":id
    });
  }
  
  getLotes()
  {
   var url_api=this.url+'SIMTOOLS.BE:C1.ACTION.LOTES.CONSULTAR';
    return this.http.get(url_api);
  }

  getLote(id)
  {
    var url_api=this.url+'SIMTOOLS.BE:C1.ACTION.LOTES.OBTENER';
     return this.http.post(url_api,{
      "ID":id
    });
  }
  getSim(id)
  {
    var url_api=this.url+'SIMTOOLS.BE:C1.ACTION.SIMCARDS.OBTENER';
     return this.http.post(url_api,{
      "ID":id
    });
  }
  saveSim(id,iccid,telefono,imei)
  {
    var url_api=this.url+'SIMTOOLS.BE:C1.ACTION.SIMCARDS.GUARDAR';
     return this.http.post(url_api,{
      "ID":id,
      "ICCID":iccid,
      "TELEFONO":telefono,
      "IMEI":imei,
    });
  }

  addLote(cliente,operador,lote,configuracion,sims)
  {
    var url_api = this.url+'SIMTOOLS.BE:C1.ACTION.LOTES.AGREGAR';
     return this.http.post(url_api,{
      "CLIENTE": cliente,
      "OPERADOR": operador,
      "NUMERO": lote,
      "CONFIGURACION": configuracion,
      "SIMS": sims
    });
  }

  editLote(id,configuracion)
  {
    var url_api=this.url+'SIMTOOLS.BE:C1.ACTION.LOTES.GUARDAR';
     return this.http.post(url_api,{
      "ID": id,
      "CONFIGURACION": configuracion,
    });
  }

  deleteLote(id)
  {
    var url_api=this.url+'SIMTOOLS.BE:C1.ACTION.LOTES.ELIMINAR';
     return this.http.post(url_api,{
      "ID":id
    });
  }
}
