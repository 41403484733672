import { Component, ViewChild , OnInit } from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {MatSnackBar} from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { RestService }from '../rest.service';

import {EliminardestinoComponent} from "./eliminardestino/eliminardestino.component";
import {MatDialog} from "@angular/material";

@Component({
  selector: 'app-destinos',
  templateUrl: './destinos.component.html',
  styleUrls: ['./destinos.component.css']
})
export class DestinosComponent implements OnInit {

  displayedColumns = ['DES_DESTINO', 'DES_TAREA', 'DES_ESTADO','OPCIONES'];
  dataSource: MatTableDataSource<DestinosData>;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor
  (
    public rest:RestService,
    public snackBar: MatSnackBar, 
    public dialogo: MatDialog
  ) { }

  ngOnInit()
  {
    this.getDestinos();
  }
  getDestinos()
  {
    this.rest.getDestinos().subscribe
    (
      Destinos =>
      {
        const destinos:DestinosData[] = Destinos["DESTINOS"];
      
        this.dataSource = new MatTableDataSource(destinos);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    );
  }

  eliminar(id)
  {
    const dialogEliminiar=this.dialogo.open
    (
      EliminardestinoComponent,
      {
        width : '500px',
      }
    );

    dialogEliminiar.afterClosed().subscribe
    (
      result =>
      {
        if(result == true)
        {
          this.rest.deleteDestino(id).subscribe
          (
            Respuesta=>
            {
              if(Respuesta["success"] == true)
              {
                this.getDestinos();
              }
            }
          );
        }
      }
    );
  }

    applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}

export interface DestinosData
{
  DES_ID: string,
  DES_DESTINO: string,
  DES_TAREA: string,
  DES_ESTADO: string,

}
