import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
  public form: FormGroup;
  mensajeError=false;

  constructor
  (
    private fb: FormBuilder,
    private router: Router
  )
  {

  }

  ngOnInit() 
  {
    this.form = this.fb.group ( {
      uname: [null , Validators.compose ( [ Validators.required ] )] ,
      password: [null , Validators.compose ( [ Validators.required ] )]
    } );

    if(localStorage.getItem('SESION')!=null)
    {
      var sesion = localStorage.getItem('SESION');
      sesion= atob(sesion);

      var dataSesion=sesion.split('_');

      var date = new Date();
      var fecha = date.getFullYear()+"-"+date.getMonth()+"-"+date.getDay();

      if(dataSesion[0]=="user:admin" && dataSesion[1] == "pass:admin" && dataSesion[2] == "fecha:"+fecha)
      {        
        this.router.navigate ( [ '/lotes' ] );
      }
      else
      {
        localStorage.removeItem("SESION");
      }
    }
  }
  onSubmit()
  {
    
    if(this.form.value.uname == "admin" && this.form.value.password == "admin")
    {
      var date = new Date();
      var fecha = date.getFullYear()+"-"+date.getMonth()+"-"+date.getDay();
      var sesion="user:admin_pass:admin_fecha:"+fecha;
      
      sesion = btoa("user:admin_pass:admin_fecha:"+fecha);
      localStorage.setItem("SESION", sesion);
      this.router.navigate ( [ '/lotes' ] );
    }
    else 
    {
      this.mensajeError=true;
    }
  }

}
