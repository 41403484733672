import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';

import { AuthComponent } from './auth/auth.component';
import { LotesComponent } from './lotes/lotes.component';
import { AddloteComponent } from './lotes/addlote/addlote.component';
import { EditloteComponent } from './lotes/editlote/editlote.component';
import { LoteComponent } from './lote/lote.component';

import { DestinosComponent } from './destinos/destinos.component';
import { AgregardestinoComponent } from './destinos/agregardestino/agregardestino.component';
import { EditardestinoComponent } from './destinos/editardestino/editardestino.component';

import { EditarComponent } from './sims/editar/editar.component';

import { AuthGuard } from './guards/auth.guard';
export const AppRoutes: Routes = [{
  path: '',
  children: [{
    path: '',
    component:AuthComponent,
    pathMatch: 'full'
  }, 
  {
    path: 'dashboards',
    loadChildren: './dashboards/dashboards.module#DashboardsModule'
  },    
  {
    path: 'material',
    loadChildren: './material-component/material.module#MaterialComponentsModule'
  }, 
  {
    path: 'catalogos',
    loadChildren: './catalogos/catalogos.module#CatalogosModule'
  },
  {
    path: 'documentos',
    loadChildren: './documentos/documentos.module#DocumentosModule'
  },
   {
    path: 'flujos',
    loadChildren: './flujos/flujos.module#FlujosModule'
  }]
}, 
{
  path:'lotes',
  component:FullComponent,
  children: 
  [
    {
      path: '',
      component:LotesComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'add',
      component:AddloteComponent,
      canActivate: [AuthGuard]
    },
    { 
      path: 'edit/:id',
      component:EditloteComponent,
      canActivate: [AuthGuard]
    },
    {
      path: ':id',
      component:LoteComponent,
      canActivate: [AuthGuard]
    }
  ]
},
{
  path:'sims',
  component:FullComponent,

  children: [{
    path: 'edit/:id/:lid',
    component:EditarComponent,
    canActivate: [AuthGuard]

  }
  ]
},
{
  path:'destinos',
  component:FullComponent,
  children: [{
    path: '',
    component:DestinosComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add',
    component:AgregardestinoComponent,
    canActivate: [AuthGuard]
  }
  ,
  {
    path: 'edit/:id',
    component:EditardestinoComponent,
    canActivate: [AuthGuard]
  }]
},
{
  path: '',
  component: AppBlankComponent,
  children: [{
    path: 'authentication',
    loadChildren: './authentication/authentication.module#AuthenticationModule'
  }]
}, 
{
  path:'',
  component: AppBlankComponent,
  children:[
  {
    path: 'simtools',
    loadChildren: './simtools/simtools.module#SimtoolsModule' 

  }]
},
{
  path: '**',
  redirectTo: 'authentication/404'
}];