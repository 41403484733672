import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { MatSnackBar } from '@angular/material';
import * as XLSX from 'xlsx';

import { HttpClient } from '@angular/common/http';
import { RestService }from '../../rest.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-editlote',
  templateUrl: './editlote.component.html',
  styleUrls: ['./editlote.component.css']
})
export class EditloteComponent implements OnInit {
  public form: FormGroup;
  data: any;
  eventos = [];
  mensajeError: string;
  errorDuracion_in: string = '';
  errorDuracion_out: string = '';
  id;
  
  displayedColumns = 
  [
    'evento',
    'dias_espera',
    'sms_out',
    'destino_sms',
    'call_out',
    'destino_call',
    'sms_in',
    'call_in',
    'data'
  ];
  

  dataSource: MatTableDataSource<EventoData>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor
  (
    private fb: FormBuilder,
    private router: Router,
    public rest: RestService,
    private rutaActiva: ActivatedRoute,
  ) { }

  ngOnInit()
  {
   this.id = this.rutaActiva.snapshot.params.id;
   this.getLote(this.id);
  }

  getLote(id)
  {
    this.rest.getLote(id).subscribe(Lote=>{
      let configuracion=[];
      configuracion = JSON.parse(Lote["DATA"]["LOT_CONFIGURACION"]);
      var i = 1;
      configuracion.forEach(element=>
      {
        switch (element["SMS_IN"]["REGEXP"]) 
        {
          case '/SU NIP ES [0-9]{4} /':
            element["SMS_IN"]["DETALLES"] = 'NIP';
          break;

          case '/\\([0-9]{2,2}\\) [0-9]{4,4} [0-9]{4,4}/':
            element["SMS_IN"]["DETALLES"] = 'TELEFONO';
          break;

          case '/\\([0-9]{3,3}\\) [0-9]{3,3} [0-9]{4,4}/':
            element["SMS_IN"]["DETALLES"] = 'TELEFONO LADA 3';
          break;

          case '/[0-9]{10,10}/':
            element["SMS_IN"]["DETALLES"] = 'TELEFONO ATT UNEFON';
          break;

          default:
            element["SMS_IN"]["DETALLES"]="";
          break;
        }
        this.eventos.push
        (
          {
            evento: 'Evento '+i,
            evento_dias_espera: element["ESPERA"],

            sms_out_cantidad: element["SMS_OUT"]["CANTIDAD"],
            sms_out_reintentos: element["SMS_OUT"]["REINTENTOS"],
            sms_out_espera: element["SMS_OUT"]["ESPERA_REINTENTAR"],
            sms_out_destinos: element["SMS_OUT"]["DESTINO"],
            sms_out_mensaje:element["SMS_OUT"]["MENSAJE"],

            sms_in_cantidad: element["SMS_IN"]["CANTIDAD"],
            sms_in_detalles: element["SMS_IN"]["DETALLES"],
            sms_in_reintentos: element["SMS_IN"]["REINTENTOS"],
            sms_in_espera: element["SMS_IN"]["ESPERA_REINTENTAR"],

            call_out_cantidad: element["CALL_OUT"]["CANTIDAD"],
            call_out_duracion: element["CALL_OUT"]["DURACION"],
            call_out_reintentos: element["CALL_OUT"]["REINTENTOS"],
            call_out_espera: element["CALL_OUT"]["ESPERA_REINTENTAR"],
            call_out_destino: element["CALL_OUT"]["DESTINO"],
            call_out_servicio: element["CALL_OUT"]["SERVICIO"] ? true : false,

            call_in_cantidad: element["CALL_IN"]["CANTIDAD"],
            call_in_duracion: element["CALL_IN"]["DURACION"],
            call_in_reintentos: element["CALL_IN"]["REINTENTOS"],
            call_in_espera: element["CALL_IN"]["ESPERA_REINTENTAR"],
        
            data_cantidad: element["DATA"]["CANTIDAD"],
            data_reintentos: element["DATA"]["REINTENTOS"],
            data_espera: element["DATA"]["ESPERA_REINTENTAR"],
            
            indice:this.eventos.length
          }
        );
        i++;
      });
      this.dataSource = new MatTableDataSource(this.eventos);
    });
  }
  agregarEvento()
  {
    var i = this.eventos.length+1;
    this.eventos.push
    (
      {
        evento: 'Evento '+i,
        evento_dias_espera:'0',

        sms_out_cantidad:'0',
        sms_out_reintentos:'0',
        sms_out_espera:'0',
        sms_out_destinos:'',
        sms_out_mensaje:'',

        sms_in_cantidad:'0',
        sms_in_detalles:'',
        sms_in_reintentos:'0',
        sms_in_espera:'0',

        call_out_cantidad:'0',
        call_out_duracion:'0',
        call_out_reintentos:'0',
        call_out_espera:'0',
        call_out_destino:'',

        call_in_cantidad:'0',
        call_in_duracion:'0',
        call_in_reintentos:'0',
        call_in_espera:'0',
        
        data_cantidad:'0',
        data_reintentos:'0',
        data_espera:'0',
        indice:this.eventos.length
      }
    );
    this.dataSource = new MatTableDataSource(this.eventos);
  }

  eliminarEvento(indice)
  {
    this.eventos.splice(indice, 1);
    let i = 0;
    this.eventos.forEach
    (
      function(element)
      {
        element.indice = i;
        i++;
        element.evento='Evento '+i;
      }
    );

    this.dataSource = new MatTableDataSource(this.eventos);
  }

  onSubmit()
  {
    var errores = false;

    if(this.eventos.length === 0)
    {
      errores = true;
      this.mensajeError = "Error: no ha capturado ningun evento";
    }
    else
    {
      var  i=1;
      this.eventos.forEach
      (
        (element) =>
        {
          if(element["call_in"] == 0 && element["call_out"] == 0 && element["sms_in"] == 0 && element["sms_out"] == 0 && element["data"] == 0)
          {
            errores = true;
            this.mensajeError = "Error: el evento "+i+" no contiene ningun parametro.";
          }
          i++;
        }
      );
    }
    const configuracion = [];
      
    this.eventos.forEach
    (
      (element)=>
      {
        let elemento = {};
        
        let destinos_callout;
        if(typeof element["call_out_destino"] != "object")
        {
          destinos_callout = element["call_out_destino"].split(',');
        }
        else
        {
          destinos_callout = element["call_out_destino"];
        }
        
        if (destinos_callout.length<2)
        {
          destinos_callout = destinos_callout[0];
        }

        let destinos_smsout;
        if(typeof element["sms_out_destinos"] != "object")
        {
          destinos_smsout = element["sms_out_destinos"].split(',');
          console.log(destinos_smsout);
        }
        else
        {
          destinos_smsout = element["sms_out_destinos"];
        }
        
        if (destinos_smsout.length<2)
        {
          destinos_smsout = destinos_smsout[0];
        }

        elemento =
        {
          'ESPERA':element["evento_dias_espera"],
          "SMS_OUT":
          {
            "CANTIDAD":element["sms_out_cantidad"],
            "REINTENTOS":element["sms_out_reintentos"],
            "ESPERA_REINTENTAR":element["sms_out_espera"],
            "DESTINO":destinos_smsout,
            "MENSAJE":element["sms_out_mensaje"],
          },
          "CALL_OUT":
          {
            "DURACION":element["call_out_duracion"],
            "CANTIDAD":element["call_out_cantidad"],
            "REINTENTOS":element["call_out_reintentos"],
            "ESPERA_REINTENTAR":element["call_out_espera"],
            "DESTINO":destinos_callout,
          },
          "SMS_IN":
          {
            "CANTIDAD":element["sms_in_cantidad"],
            "REINTENTOS":element["sms_in_reintentos"],
            "ESPERA_REINTENTAR":element["sms_in_espera"],
          },
          "CALL_IN":
          {
            "DURACION":element["call_in_duracion"],
            "CANTIDAD":element["call_in_cantidad"],
            "REINTENTOS":element["call_in_reintentos"],
            "ESPERA_REINTENTAR":element["call_in_espera"],
          },
          "DATA":
          {
            "CANTIDAD":element["data_cantidad"],
            "REINTENTOS":element["data_reintentos"],
            "ESPERA_REINTENTAR":element["data_espera"],
          }
        }
        switch(element["sms_in_detalles"])
        {
          
          case 'NIP':
            elemento["SMS_IN"]["REGEXP"] = '/TU NIP ES [0-9]{4} /';
            elemento["SMS_IN"]["DATA"] = '0';
            elemento["SMS_IN"]['OMITIR_SERVICIO'] = 1;
          break;

          case 'TELEFONO':
            elemento["SMS_IN"]["REGEXP"] = '/\\([0-9]{2,2}\\) [0-9]{4,4} [0-9]{4,4}/';
            elemento["SMS_IN"]["DATA"] = '0';
            elemento["SMS_IN"]['OMITIR_SERVICIO'] = 1;
          break;

          case 'TELEFONO LADA 3':
            elemento["SMS_IN"]["REGEXP"] = '/\\([0-9]{3,3}\\) [0-9]{3,3} [0-9]{4,4}/';
            elemento["SMS_IN"]["DATA"] = '0';
            elemento["SMS_IN"]['OMITIR_SERVICIO'] = 1;
          break;

          case 'TELEFONO ATT UNEFON':
            elemento["SMS_IN"]["REGEXP"] = '/[0-9]{10,10}/';
            elemento["SMS_IN"]["DATA"] = '0';
            elemento["SMS_IN"]['OMITIR_SERVICIO'] = 1;
          break;

          default:
          break;

        }
        if(element["call_out_servicio"])
        {
          elemento["CALL_OUT"]["SERVICIO"]=true;
        }
        configuracion.push(elemento);
      }
    );

    if(errores == false)
    {
      console.log(configuracion);
      this.rest.editLote(this.id ,configuracion).subscribe
      (
        Lote=>
        {
          if(Lote["success"] == false)
          {
            if(Lote["RETURN"] == "E")
            {
              this.mensajeError = "Error: el lote ya existe";
            }
            else
            {
              this.mensajeError = "Error: no se pudo agregar el lote";
            }
          }
          else
          {
            this.router.navigate ( [ '/lotes' ] );
          }
        }
      );
    }
  }

}
export interface ConfiguracionData
{
  cliente:string,
  operador:string,
  numero:string
}
export interface EventoData
{
  evento:any,
  indice:any,
  evento_dias_espera:any,

  sms_out_cantidad:any,
  sms_out_reintentos:any,
  sms_out_espera:any,
  sms_out_destinos:any,
  sms_out_mensaje:any,

  sms_in_cantidad:any,
  sms_in_detalles:any,
  sms_in_reintentos:any,
  sms_in_espera:any,

  call_out_cantidad:any,
  call_out_duracion:any,
  call_out_reintentos:any,
  call_out_espera:any,
  call_out_destino:any,
  call_out_servicio:any,

  call_in_cantidad:any,
  call_in_duracion:any,
  call_in_reintentos:any,
  call_in_espera:any,

  data_cantidad:any,
  data_reintentos:any,
  data_espera:any,
}