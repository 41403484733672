import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { RestService }from '../../rest.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-editardestino',
  templateUrl: './editardestino.component.html',
  styleUrls: ['./editardestino.component.css']
})
export class EditardestinoComponent implements OnInit {
  id:any;
  destino:any;
  tipo:any;
  estado:any;
  mensajeError:string='';
  mensaje:string='';

  public form: FormGroup;


  constructor(
    private fb: FormBuilder,
    public rest:RestService,
    private rutaActiva: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() 
  {
    this.form = this.fb.group ( {
      destino: [null , Validators.compose ( [ Validators.required ] )],
      tipo: [null , Validators.compose ( [ Validators.required ] )],
      estado: [null , Validators.compose ( [ Validators.required ] )],
    } );
    this.id=this.rutaActiva.snapshot.params.id;
    this.getDestino(this.id);
  }

  getDestino(id)
  {
    this.rest.getDestino(id).subscribe(Destino=>
    {
      this.form.controls['destino'].setValue(Destino["DATA"]["DES_DESTINO"]);
      this.form.controls['tipo'].setValue(Destino["DATA"]["DES_TAREA"]);
      this.form.controls['estado'].setValue(Destino["DATA"]["DES_ESTADO"]);
    });
  }

  onSubmit(formDirective)
  {
    let destino = this.form.value.destino;
    let tipo = this.form.value.tipo;
    let estado = this.form.value.estado;

    if(tipo == "CALL_OUT" || tipo == "SMS_OUT")
    {
     
      if(!isNaN(destino) && destino.length == 10)
      {
        this.mensajeError = '';
      }
      else
      {
        this.mensajeError = "Error: el telefono no es valido";
      }

    }
    else
    {
      var regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
   
     if(!regexp.test(destino))
     {
      this.mensajeError = "Error: la url no es valida";
     }
     else
     {
       this.mensajeError = '';
     }
      
    }

    if(this.mensajeError == '')
    {
      this.rest.saveDestino(this.id,destino,tipo,estado).subscribe(Destino =>
      {
         if(Destino["success"] == false)
        {
          if(Destino["RETURN"] == "E")
          {
            this.mensajeError = "Error: el destino ya existe";
          }
          else
          {
            this.mensajeError = "Error: no se pudo guardar el destino";
          }
        }
        else
        {
          //this.form.reset();
          //formDirective.resetForm();
          this.mensaje="EL destino se ha guardado";
          this.router.navigate ( [ '/destinos' ] );
        }
        
      });
    }
    
  }

}
