import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { RestService }from '../../rest.service';
import { Router } from '@angular/router';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-editar',
  templateUrl: './editar.component.html',
  styleUrls: ['./editar.component.css']
})
export class EditarComponent implements OnInit {
  public form: FormGroup;
  id;
  lote;


  constructor(
    private fb: FormBuilder,
    public rest:RestService,
    private rutaActiva: ActivatedRoute,
    private router: Router,
    public snackBar : MatSnackBar,
  ) { }

ngOnInit() 
  {
    this.form = this.fb.group ( {
      iccid: [null ],
      telefono: [null ],
      imei: [null ],
    } );
    this.id=this.rutaActiva.snapshot.params.id;
    this.lote=this.rutaActiva.snapshot.params.lid;
    this.getSim(this.id);
  }
  getSim(id)
  {
    this.rest.getSim(id).subscribe(Respose=>
    {

      this.form.controls['iccid'].setValue(Respose["DATA"]["SIM_ICCID"]);
      this.form.controls['telefono'].setValue(Respose["DATA"]["SIM_TELEFONO"]);
      this.form.controls['imei'].setValue(Respose["DATA"]["SIM_IMEI"]);
    });
  }
  onSubmit()
  {
    this.rest.saveSim(this.id,this.form.value.iccid,this.form.value.telefono,this.form.value.imei).subscribe(Response =>
      {
        
        console.log(Response);

       if(Response["success"] == false)
        {
          this.snackBar.open('No se pudo modificar el Simcard', '', {duration: 2000});
        }
        else
        {
          this.snackBar.open('Se modifico el Simcard', '', {duration: 2000});
          this.router.navigate ( [ '/lotes/'+this.lote ] );
        }
        
      });
  }
  

}
