import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent 
{
  constructor
  (
    private router: Router
  )
  {

  }
  cerrarSesion()
  {
    localStorage.clear();
    this.router.navigate ( [ '/' ] );
  }
}
