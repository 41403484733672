import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { MatSnackBar } from '@angular/material';
import * as XLSX from 'xlsx';

import { HttpClient } from '@angular/common/http';
import { RestService }from '../../rest.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-addlote',
  templateUrl: './addlote.component.html',
  styleUrls: ['./addlote.component.css']
})
export class AddloteComponent implements OnInit {
  public form: FormGroup;
  data: any;
  eventos: EventoData[] = [];
  mensajeError: string;
  errorDuracion_in: string = '';
  errorDuracion_out: string = '';

  
  displayedColumns = 
  [
    'evento',
    'dias_espera',
    'sms_out',
    'destino_sms',
    'call_out',
    'destino_call',
    'sms_in',
    'call_in',
    'data'
  ];
  

  dataSource: MatTableDataSource<EventoData>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor
  (
    private fb: FormBuilder,
    private router: Router,
    public rest: RestService
  ) { }

 ngOnInit()
 {
    this.form = this.fb.group 
    ( 
      {
        cliente: [null , Validators.compose ( [ Validators.required ] )],
        operador: [null , Validators.compose ( [ Validators.required ] )],
        lote: [null , Validators.compose ( [ Validators.required ] )],
        archivo: [null , Validators.compose ( [ Validators.required ] )],
      } 
    ); 
  }

  agregarEvento()
  {
    var i = this.eventos.length+1;
    this.eventos.push
    (
      {
        evento: 'Evento '+i,
        evento_dias_espera:'0',

        sms_out_cantidad:'0',
        sms_out_reintentos:'0',
        sms_out_espera:'0',
        sms_out_destinos:'',
        sms_out_mensaje:'',

        sms_in_cantidad:'0',
        sms_in_detalles:'',
        sms_in_reintentos:'0',
        sms_in_espera:'0',

        call_out_cantidad:'0',
        call_out_duracion:'0',
        call_out_reintentos:'0',
        call_out_espera:'0',
        call_out_destino:'',

        call_in_cantidad:'0',
        call_in_duracion:'0',
        call_in_reintentos:'0',
        call_in_espera:'0',
        
        data_cantidad:'0',
        data_reintentos:'0',
        data_espera:'0',
        indice:this.eventos.length
      }
    );
    this.dataSource = new MatTableDataSource(this.eventos);
  }

  eliminarEvento(indice)
  {
    this.eventos.splice(indice, 1);
    let i = 0;
    this.eventos.forEach
    (
      function(element)
      {
        element.indice = i;
        i++;
        element.evento='Evento '+i;
      }
    );

    this.dataSource = new MatTableDataSource(this.eventos);
  }

  onSubmit()
  {
    var cliente = this.form.value.cliente;
    var operador = this.form.value.operador;
    var lote = this.form.value.lote;
    var errores=false;
    var sims=Array();

    if(this.data.length === 0)
    {
      this.mensajeError = "Error: el documento no contiene registros";
      errores = true;
    }
    else
    {
      let i=1;
      
      this.data.forEach
      (

        (element) =>
        {/*
          if(!element[0] || !element[1] || !element[2])
          {
            this.mensajeError = "Error: uno de los registros tiene campos vacios";
            errores = true;
          }
          if(typeof element[0] != 'string')
          {
            element[0]=String(element[0]);
          }
          if(element[0].length == 19)
          {
          }
          else
          {
            this.mensajeError = "Error: el ICCID de la fila "+i+" no es correcto";
            errores = true;
          }
          if(typeof element[1] != 'string')
          {
            element[1]=String(element[1]);
          }
          if(element[1].length == 10)
          {

          }
          else
          {
            this.mensajeError = "Error: el telefono de la fila "+i+" no es correcto";
          }
          if(typeof element[2] != 'string')
          {
            element[2]=String(element[2]);
          }
          if(element[2].length == 15)
          {

          }
          else
          {
            this.mensajeError = "Error: el IMEI de la fila "+i+" no es correcto";
            errores = true;
          }
          i++;*/
          
        }
      );
      if (errores == false) 
      {
        this.mensajeError='';

      }

    }


    if(this.eventos.length === 0)
    {
      errores = true;
      this.mensajeError = "Error: no ha capturado ningun evento";
    }
    else
    {
      var  i=1;
      this.eventos.forEach
      (
        (element) =>
        {
          if(element["call_in"] == 0 && element["call_out"] == 0 && element["sms_in"] == 0 && element["sms_out"] == 0 && element["data"] == 0)
          {
            errores = true;
            this.mensajeError = "Error: el evento "+i+" no contiene ningun parametro.";
          }
          i++;
        }
      );
    }
    const configuracion = [];
      
    this.eventos.forEach
    (
      (element)=>
      {
        let elemento = {};
        let destinos_callout=element["call_out_destino"].split(',');
        if (destinos_callout.length<2)
        {
          destinos_callout=destinos_callout[0];
        }
        let destinos_smsout=element["sms_out_destinos"].split(',');
        if (destinos_smsout.length<2)
        {
          destinos_smsout=destinos_smsout[0];
        }

        elemento =
        {
          'ESPERA':element["evento_dias_espera"],
          "SMS_OUT":
          {
            "CANTIDAD":element["sms_out_cantidad"],
            "REINTENTOS":element["sms_out_reintentos"],
            "ESPERA_REINTENTAR":element["sms_out_espera"],
            "DESTINO":destinos_smsout,
            "MENSAJE":element["sms_out_mensaje"],
          },
          "CALL_OUT":
          {
            "DURACION":element["call_out_duracion"],
            "CANTIDAD":element["call_out_cantidad"],
            "REINTENTOS":element["call_out_reintentos"],
            "ESPERA_REINTENTAR":element["call_out_espera"],
            "DESTINO":destinos_callout,
          },
          "SMS_IN":
          {
            "CANTIDAD":element["sms_in_cantidad"],
            "REINTENTOS":element["sms_in_reintentos"],
            "ESPERA_REINTENTAR":element["sms_in_espera"],
          },
          "CALL_IN":
          {
            "DURACION":element["call_in_duracion"],
            "CANTIDAD":element["call_in_cantidad"],
            "REINTENTOS":element["call_in_reintentos"],
            "ESPERA_REINTENTAR":element["call_in_espera"],
          },
          "DATA":
          {
            "CANTIDAD":element["data_cantidad"],
            "REINTENTOS":element["data_reintentos"],
            "ESPERA_REINTENTAR":element["data_espera"],
          }
        }
        switch(element["sms_in_detalles"])
        {
          
          case 'NIP':
            elemento["SMS_IN"]["REGEXP"] = '/TU NIP ES [0-9]{4} /';
            elemento["SMS_IN"]["DATA"] = '0';
            elemento["SMS_IN"]['OMITIR_SERVICIO'] = 1;
          break;

          case 'TELEFONO':
            elemento["SMS_IN"]["REGEXP"] = '/\\([0-9]{2,2}\\) [0-9]{4,4} [0-9]{4,4}/';
            elemento["SMS_IN"]["DATA"] = '0';
            elemento["SMS_IN"]['OMITIR_SERVICIO'] = 1;
          break;

          case 'TELEFONO LADA 3':
            elemento["SMS_IN"]["REGEXP"] = '/\\([0-9]{3,3}\\) [0-9]{3,3} [0-9]{4,4}/';
            elemento["SMS_IN"]["DATA"] = '0';
            elemento["SMS_IN"]['OMITIR_SERVICIO'] = 1;
          break;

          case 'TELEFONO ATT UNEFON':
            elemento["SMS_IN"]["REGEXP"] = '/[0-9]{10,10}/';
            elemento["SMS_IN"]["DATA"] = '0';
            elemento["SMS_IN"]['OMITIR_SERVICIO'] = 1;
          break;

          default:
          break;

        }
        if(element["call_out_servicio"])
        {
          elemento["CALL_OUT"]["SERVICIO"]=true;
        }
        configuracion.push(elemento);
      }
    );

    console.log(configuracion);
    if(errores == false)
    {
      
      this.rest.addLote(cliente,operador,lote,configuracion,this.data).subscribe
      (
        Lote=>
        {
          if(Lote["success"] == false)
          {
            if(Lote["RETURN"] == "E")
            {
              this.mensajeError = "Error: el lote ya existe";
            }
            else
            {
              this.mensajeError = "Error: no se pudo agregar el lote";
            }
          }
          else
          {
            this.router.navigate ( [ '/lotes' ] );
          }
        }
      );
    }
  }


  onFileChange(evt: any) 
  {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <any>(XLSX.utils.sheet_to_json(ws, {header: 1}));
      
    };
    reader.readAsBinaryString(target.files[0]);

  }
}
export interface ConfiguracionData
{
  cliente:string,
  operador:string,
  numero:string
}
export interface EventoData
{
  evento:any,
  indice:any,
  evento_dias_espera:any,

  sms_out_cantidad:any,
  sms_out_reintentos:any,
  sms_out_espera:any,
  sms_out_destinos:any,
  sms_out_mensaje:any,

  sms_in_cantidad:any,
  sms_in_detalles:any,
  sms_in_reintentos:any,
  sms_in_espera:any,

  call_out_cantidad:any,
  call_out_duracion:any,
  call_out_reintentos:any,
  call_out_espera:any,
  call_out_destino:any,

  call_in_cantidad:any,
  call_in_duracion:any,
  call_in_reintentos:any,
  call_in_espera:any,

  data_cantidad:any,
  data_reintentos:any,
  data_espera:any,
}